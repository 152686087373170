import * as React from "react";
import LpJccIntakeFormPage from "../components/routes/LPJCCIntakeFormPage/JccIntakeFormPage";
import Layout from "../components/layout/MainLayout";
import Theme from "../vars/ThemeOptions";

const HomeShow2022Page = () => {
  return (
    <Layout
      layoutProps={{
        logoSlug: "jccintakeform",
        headLogoColor: Theme.hex("white"),
        showCta: true,
        headCtaStyle: "simple",
        headCtaColor: Theme.hex("white")
      }}>
      <LpJccIntakeFormPage
        title="Elevation Celebration"
        formName={"elevation_celebration_2022"}
        thanks="/elevations2022-success"
        leadStatus="Lead Status"
        springHomeShow
      />
    </Layout>
  );
};

export default HomeShow2022Page;
